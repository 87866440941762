import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const useCheckRewardsData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const isGuest = useIsGuest();
	const { invitedByCookie } = useCookiesSetPromoCode();
	const promoCode = useState("refCode");

	const code = computed(() => promoCode.value || invitedByCookie.value);

	const fetchOptions = () => {
		if (!isGuest.value || !code.value) {
			return { query: {} };
		}

		return { query: { ...(code.value && { invited_by: code.value }) } };
	};

	const { data, refresh } = useAsyncFetch({
		path: "/rest/rewards/check-rewards/",
		method: "get",
		options: {
			immediate,
			cached: code.value ? undefined : true,
			watch: [() => !isGuest.value]
		},
		fetchOptions
	});

	const registrationRewards = computed(() => {
		if (code.value && data.value?.registrationReferralReward?.coins) {
			return data.value?.registrationReferralReward;
		}

		return data.value?.["0"]?.registration;
	});

	const emailRewards = computed(() => data.value?.["0"]?.emailConfirmation);
	const phoneRewards = computed(() => data.value?.["0"]?.phoneConfirmation);
	const profileRewards = computed(() => data.value?.["0"]?.profileComplete);
	const isPersonalOffer = computed(
		() =>
			code.value && (data.value?.registrationReferralReward?.coins || data.value?.registrationReferralReward?.entries)
	);

	const showAnimation = useState<boolean>("animationCoinPouring", () => false);
	const stepOnBoard = useState<boolean>("stepOnBoard", () => false);
	const showAnimationCoinPouring = computed(() => showAnimation.value && stepOnBoard.value);

	return {
		refresh,
		registrationRewards,
		emailRewards,
		phoneRewards,
		profileRewards,
		isPersonalOffer,
		showAnimationCoinPouring,
		stepOnBoard
	};
};

export default useCheckRewardsData;
